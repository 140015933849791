<template>
  <div class="card py-3">
    <b-row no-gutters class="card__top align-items-end">
      <b-img @click="toProfile" :src="article.author.image" class="img-fill-height"/>
      <b-col class="card__top__info text-left pl-2">
        <h6 @click="toProfile" class="m-0">{{ article.author.username }}</h6>
        <p class="m-0">{{ article.createdAt | transformInDate }}</p>
      </b-col>
      <b-col class="card__top__favorite align-self-start d-flex justify-content-end">
        <b-button 
        :disabled="isLoading || userHasLogged === false"
        @click="followingOrUnfollowingFavorite"
        class="px-1 py-0 d-flex justify-content-center align-items-center" 
        :variant="hasFavorited ? 'success' : 'outline-success' ">
          <b-icon icon="heart" class="mr-2"/> {{ favoritesCount }}
        </b-button>
      </b-col>
    </b-row>
    <b-row no-gutters class="card__bottom mt-2">
      <b-col cols=12
      @click="toArticle" 
      class="card__bottom__title text-left f-bold">
        {{ article.title }}
      </b-col>
      <b-col cols=12 
      @click="toArticle" 
      class="card__bottom__content text-left mb-3">
        {{ article.description }}
      </b-col>
      <b-col cols=12 class="card__bottom__more text-left d-flex justify-content-between">
        <router-link :to="`/article/${article.slug}`" class="text-muted">
          Ler mais...
        </router-link>
        <div class="card__bottom__more__tags">
          <b-button v-for="tag in article.tagList"
          disabled
          variant="outline-secondary m-1"
          size="sm"
          pill
          :key="tag">
            {{ tag }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      hasFavorited: this.article.favorited,
      favoritesCount: this.article.favoritesCount
    }
  },
  methods: {
    toProfile () {
      this.$router.push({
        path: `/@${this.article.author.username}`
      }).catch(() => {})
    },
    toArticle () {
      this.$router.push({
        path: `/article/${this.article.slug}`
      })

    },
    followingOrUnfollowingFavorite () {
      if (this.hasFavorited) {
        this.unfavoriteArticle()
        } else {
        this.favoriteArticle()
      }
    },
    favoriteArticle () {
      this.isLoading = true
      this.$http.favoriteArticle(this.article.slug)
        .then( ({ data }) => {
          this.hasFavorited = data.article.favorited
          this.favoritesCount = data.article.favoritesCount
        }).finally( () => {
          this.isLoading = false
        })
    },
    unfavoriteArticle () {
      this.isLoading = true
      this.$http.unfavoriteArticle(this.article.slug)
        .then( ({ data }) => {
          this.hasFavorited = data.article.favorited
          this.favoritesCount = data.article.favoritesCount
        }).finally( () => {
          this.isLoading = false
        })
    },

  }
}
</script>

<style lang="scss">
.hover-and-click {
  text-decoration: underline;
  cursor: pointer;
}
.card {
  border: none;
  border-radius: 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid lighten($dark, 93%);
  }
  &__top {
    height: 40px;
    img {
      border-radius: 50%;
      width: 40px;
      object-fit: cover;
      height: 40px;
      &:hover { @extend .hover-and-click;}
    }
    &__info {
      h6 {
        font-size: 1rem;
        color: $color1;
        &:hover { @extend .hover-and-click;}
      }
      p {
        font-size: .8rem;
      }
    }
  }
  &__bottom {
    &__title {
      font-size: 1.5rem;
      text-decoration: none !important;
      &:hover { @extend .hover-and-click;}
    }
    &__content {
      font-size: 1rem;
      color: lighten($dark, 70%);
      text-decoration: none !important;
      &:hover { @extend .hover-and-click;}
    }
    &__more {
      font-size: .8rem;
      a {
        &:hover { @extend .hover-and-click;}
      }
      &__tags {
        max-width: 60%;
      }
    }
  }
}
</style>