<template>
  <b-row class="profile">
    <b-col class="profile__header pt-4 pb-3" cols=12>
      <b-container class="d-flex flex-column align-items-center">
        <div class="profile__header__container-img d-flex justify-content-center mb-3">
          <b-img :src="profile.image" class="img-fill-height"/>
        </div>
        <h4 class="f-bold">{{ profile.username }}</h4>
        <p>{{ profile.bio }}</p>
        <b-button @click="typeClickButton" 
        v-if="userHasLogged"
        :disabled="isLoading"
        variant="outline-secondary" 
        size="sm" 
        class="align-self-end">
          <b-icon :icon="typeIcon"/> {{ editProfileOrFollow }}
        </b-button>
      </b-container>
    </b-col>
    <b-container>
      <b-nav tabs class="mt-4 mb-2">
        <b-nav-item 
        :active="activeNavItem === 'myArticles'"
        @click="setMyArticles()">
          My Articles
        </b-nav-item>
        <b-nav-item 
        :active="activeNavItem === 'myFavoritedArticles'"
        @click="setMyFavoritedArticles()">
          Favorited Articles
        </b-nav-item>
      </b-nav>
      <AppCard v-for="article in articles"
      :key="article.slug"
      :article="article"/>
       <b-pagination
       v-if="totalArticles > 5"
      v-model="currentPage"
      :total-rows="totalArticles"
      :per-page="perPage"/>
      <div v-if="totalArticles === 0 && isLoading === false" class="mt-4">No articles are here... yet.</div>
      <b-spinner v-if="isLoading" label="Loading..." class="my-4"/>
    </b-container>
  </b-row>
</template>

<script>
import AppCard from '@/components/Card.vue'

export default {
  metaInfo () {
    return {
      title: `${this.profile?.username || 'Profile'} | test-almocogratis`
    }
  },
  components: {
    AppCard
  },
  async beforeMount () {
    this.profile = await this.$http.getProfile(this.usernameProfile)
      .then( ({ data }) => {
        return data.profile
      })
      this.setMyArticles()
  },
  data () {
    return {
      activeNavItem: 'myArticles',

      currentPage: 1,
      totalArticles: 0,
      perPage: 5,

      isLoading: false,
      profile: {
        username: undefined,
        follow: undefined,
        image: undefined,
        bio: undefined
      },
      articles: []
    }
  },
  computed: {
    usernameProfile () {
      return this.$route.params.username
    },
    typeProfile () {
      const username = this.getStore('user/data', 'username')
      if (username === this.usernameProfile) return 'HAS_OWNER'
      else return 'HAS_VISIT'
    },
    editProfileOrFollow () {
      if (this.typeProfile === 'HAS_OWNER') return 'Edit profile Settings'
      else if (this.profile.following) return `Unfollow ${this.usernameProfile}`
      else return `Follow ${this.usernameProfile}`
    },
    typeIcon () {
      if (this.typeProfile === 'HAS_OWNER') return 'gear'
      else return 'plus'
    }
  },
  watch: {
    currentPage () {
      this.typeSetArticle()
    },
    async usernameProfile () {
      this.profile = await this.$http.getProfile(this.usernameProfile)
        .then( ({ data }) => {
          return data.profile
        })
      this.setMyArticles()
    }
  },
  methods: {
    typeClickButton () {
      if (this.typeProfile === 'HAS_OWNER') {
        this.openSettingProfile ()
      } else if (this.profile.following) {
        this.unfollowingProfile()
      } else {
        this.followingProfile()
      }
    },
    typeSetArticle () {
      if (this.activeNavItem === 'myArticles') {
        this.setMyArticles()
      } else {
        this.setMyFavoritedArticles()
      }
    },
    openSettingProfile () {
      this.$router.push({ name: 'Settings' })
    },
    async unfollowingProfile () {
      this.isLoading = true
      await this.$http.unfollowingProfile(this.usernameProfile)
      .then( ({ data }) => {
        this.profile = data.profile
      }).finally( () => {
        this.isLoading = false
      })
    },
    async followingProfile () {
      this.isLoading = true
      await this.$http.followingProfile(this.usernameProfile)
      .then( ({ data }) => {
        this.profile = data.profile
      }).finally( () => {
        this.isLoading = false
      })
    },
    async setMyArticles () {
      this.isLoading = true
      this.activeNavItem = 'myArticles'
      this.articles = []
      this.totalArticles = 0
      await this.$http.articles({
          author: this.usernameProfile,
          limit: this.perPage,
          offset: (this.currentPage * this.perPage) - this.perPage
        }).then( ({ data }) => {
          this.articles = data.articles
          this.totalArticles = data.articlesCount
          this.currentPage = 1
        }).finally( () => {
          this.isLoading = false
        })
    },
    async setMyFavoritedArticles () {
      this.isLoading = true
      this.activeNavItem = 'myFavoritedArticles'
      this.articles = []
      this.totalArticles = 0
      await this.$http.articles({
          favorited: this.usernameProfile,
          limit: this.perPage,
          offset: (this.currentPage * this.perPage) - this.perPage
        }).then( ({ data }) => {
          this.articles = data.articles
          this.totalArticles = data.articlesCount
          this.currentPage = 1
        }).finally( () => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
  .profile {
    &__header {
      min-height: 225px;
      background-color: lighten($dark, 95%);
      &__container-img {
        height: 100px;
        img {
          width: 100px;
          object-fit: cover;
          height: 100px;
          border-radius: 50%;
        }
      }
      p {
        max-width: 450px;
        width: 100%;
        color: lighten($dark, 70%);
      }
    }
  }
</style>